<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-layout>
      <v-progress-linear
        color="#7CBF46"
        v-show="loader"
        :indeterminate="true"
      ></v-progress-linear>
    </v-layout>

    <v-layout wrap>
      <v-col cols="12" md="3">
        <RetailerMenu></RetailerMenu>
      </v-col>

      <v-col cols="12" md="9">
        <v-row>
          <v-col cols="12">
            <h3>My Shipping Address</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-dialog v-model="dialog" width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="buttonStyle" dark v-bind="attrs" v-on="on">
                  Add new address
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <h6 class="pt-4">Contact</h6>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="newShippingAddress.name"
                        solo
                        label="Name"
                        hint="Example: Chime Emmanuel Ndam"
                        persistent-hint
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="newShippingAddress.phoneNumber"
                        solo
                        label="Phone Number"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <h6 class="pt-4">Address</h6>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="newShippingAddress.address"
                        solo
                        label="Street, house/apartment/unit"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="4">
                      <v-select
                        :items="country"
                        label="Country"
                        solo
                        dense
                        item-text="name"
                        v-model="newShippingAddress.country"
                        @change="getCountryStates"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-select
                        :items="states"
                        label="Select City"
                        solo
                        dense
                        item-text="name"
                        v-model="newShippingAddress.city"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-text-field
                        v-model="newShippingAddress.zipCode"
                        solo
                        dense
                        label="Zip code"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-switch
                      v-model="newShippingAddress.isDefault"
                      label="Make Default"
                    ></v-switch>
                  </v-row>
                  <v-layout color="#BD2121" v-if="error">
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-danger"
                            ><v-icon color="#F81515">mdi-cancel</v-icon>
                            {{ errorMessage }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-layout>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn class="buttonStyle" @click="saveAddress">
                    Save
                  </v-btn>

                  <v-btn outlined color="black" @click="dialog = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="rowBg mt-5"
            v-for="(detail, index) in shippingAddress"
            :key="index"
          >
            <div align="right">
              <p
                class="defaultAddressStyle text-right"
                v-if="detail.isDefault == true"
              >
                Default Address
              </p>
            </div>
            <v-layout class="mt-3" color="black">
              <v-icon class="mr-5">mdi-account</v-icon> {{ detail.name }}
            </v-layout>
            <v-layout class="mt-4" color="black">
              <v-icon class="mr-5">mdi-cellphone-text</v-icon>
              {{ detail.phoneNumber }}
            </v-layout>
            <v-layout class="mt-4" color="black">
              <v-icon class="mr-5">mdi-map-marker</v-icon> {{ detail.address }}
              {{ detail.city }}, {{ detail.country }}, {{ detail.zipcode }}
            </v-layout>

            <v-layout class="mt-4">
              <v-btn
                text
                color="success"
                @click.prevent="editAddress(detail.shippingAddressId)"
              >
                Edit
              </v-btn>
              <v-btn
                text
                color="error"
                @click.prevent="confirmDeleteAddress(detail.shippingAddressId)"
              >
                Delete
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>

        <v-dialog v-model="dialogEdit" width="600">
          <v-card>
            <v-card-title class="text-h6 grey lighten-2">
              Edit Address
            </v-card-title>
            <v-card-text>
              <h6 class="pt-4">Contact</h6>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="addressUpdate.name"
                    solo
                    label="Name"
                    hint="Example: Chime Emmanuel Ndam"
                    persistent-hint
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addressUpdate.phoneNumber"
                    solo
                    label="Phone Number"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <h6 class="pt-4">Address</h6>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="addressUpdate.address"
                    solo
                    label="Street, house/apartment/unit"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="4">
                  <v-select
                    :items="country"
                    label="Solo field"
                    solo
                    dense
                    item-text="name"
                    v-model="addressUpdate.country"
                  ></v-select>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="addressUpdate.city"
                    solo
                    dense
                    label="City"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="addressUpdate.zipcode"
                    solo
                    dense
                    label="Zip code"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-switch
                  v-model="addressUpdate.isDefault"
                  label="Make Default"
                ></v-switch>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn outlined color="black" @click="dialogEdit = false">
                Cancel
              </v-btn>

              <v-btn
                class="buttonStyle"
                @click="editShippingAddress(addressUpdate)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" width="400">
          <v-card>
            <v-card-text>
              <h5 class="pt-4">Delete Shipping address</h5>
              <p>
                Are you sure you want to delete this shipping address?
              </p></v-card-text
            >

            <v-card-actions>
              <v-btn
                class="buttonStyle"
                @click="deleteAddress(addressUpdate.shippingAddressId)"
              >
                Yes
              </v-btn>

              <v-btn outlined color="black" @click="dialogDelete = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import RetailerMenu from "../others/RetailerMenu.vue";
import { Country, State } from "country-state-city";

export default {
  name: "ShippingAddress",

  components: {
    RetailerMenu,
  },

  data: () => ({
    dialog: false,
    dialogEdit: false,
    dialogDelete: false,
    errorMessage: [],
    loader: false,
    checkbox: false,
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/retailer_dashboard/home",
      },
      {
        text: "Shipping",
        disabled: true,
        href: "/#/",
      },
    ],
    newShippingAddress: {
      name: "",
      phoneNumber: "",
      address: "",
      country: "",
      city: "",
      zipCode: "",
      isDefault: false,
    },
    shippingAddress: [],
    id: "",
    addressUpdate: {
      name: "",
      phoneNumber: "",
      address: "",
      country: "",
      city: "",
      zipCode: "",
      isDefault: null,
    },
    error: "",
    states: [],
    selectedCountry: {},
  }),
  created() {
    this.getCountry();
    this.userShippingAddress();
  },
  methods: {
    userShippingAddress() {
      this.$http
        .get(
          `${process.env.VUE_APP_URL}user/shippingaddresses/` +
            localStorage.getItem("userId")
        )
        .then((response) => {
          console.log(response.data.data);
          this.shippingAddress = response.data.data;
        });
    },

    editAddress(id) {
      this.dialogEdit = true;
      this.shippingAddress.forEach((address) => {
        if (address.shippingAddressId == id) {
          this.addressUpdate = address;
          this.id = address.shippingAddressId;
        }
      });
    },
    editShippingAddress(addressUpdate) {
      this.loader = true;
      this.errorMessage = [];
      let data = {
        name: this.newShippingAddress.name,
        phoneNumber: this.newShippingAddress.phoneNumber,
        address: this.newShippingAddress.address,
        country: this.newShippingAddress.country,
        city: this.newShippingAddress.city,
        zipCode: this.newShippingAddress.zipCode,
        isDefault: this.newShippingAddress.isDefault,
      };

      this.$http
        .put(
          `${process.env.VUE_APP_URL}user/shippingaddress/` +
            addressUpdate.shippingAddressId,
          data
        )
        .then((response) => {
          console.log(response);
          this.loader = false;
          location.reload();
        })
        .catch((error) => {
          this.loader = false;
          this.error = true;
          console.log(error.response);
          this.errorMessage = error.response.data.data;
        });
    },

    confirmDeleteAddress(id) {
      this.dialogDelete = true;
      this.shippingAddress.forEach((address) => {
        if (address.shippingAddressId == id) {
          this.addressUpdate = address;
          this.id = address.shippingAddressId;
        }
      });
    },
    deleteAddress(id) {
      console.log(id);
      this.dialogDelete = false;
      this.$http
        .post(`${process.env.VUE_APP_URL}user/shippingaddress/` + id)
        .then((response) => {
          console.log(response);
          this.loader = false;
          // location.reload();
        })
        .catch((error) => {
          this.loader = false;
          console.log(error.response);
        });
    },
    getCountry() {
      this.country = Country.getAllCountries();
    },
    getCountryStates(evt) {
      this.country.forEach((element) => {
        if (element.name == evt) {
          this.states = State.getStatesOfCountry(element.isoCode);
        }
      });
    },
    saveAddress() {
      this.loader = true;
      this.errorMessage = [];
      const userId = localStorage.getItem("userId");
      let data = {
        name: this.newShippingAddress.name,
        phoneNumber: this.newShippingAddress.phoneNumber,
        address: this.newShippingAddress.address,
        country: this.newShippingAddress.country,
        city: this.newShippingAddress.city,
        zipCode: this.newShippingAddress.zipCode,
        isDefault: this.newShippingAddress.isDefault,
      };

      this.$http
        .post(
          `${process.env.VUE_APP_URL}user/shippingaddress/` + userId,
          data
        )
        .then((response) => {
          console.log(response);
          this.loader = false;
          location.reload();
        })
        .catch((error) => {
          this.loader = false;
          this.error = true;
          console.log(error.response);
          this.errorMessage = error.response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0px 0px 20px 20px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

li {
  font-size: 12px;
  margin-top: 5px;
}

.defaultAddressStyle {
  width: 130px;
  height: auto;
  background: rgba(17, 18, 18, 0.47);
  color: #ffffff;
  padding: 5px;
  border-top-left-radius: 5px;
}
</style>
